<template>
  <div>
    <pdf 
      v-for="i in numPages"
			:key="i"
			:page="i"
			style="height: 100%" 
      :src="src">
    </pdf>
    
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'Guide',
  created() {
    console.log(this.publicPath)
  },
  data () {
    return {
      src: undefined,
      numPages: undefined,
      publicPath: `${window.location.origin}/chatwork.pdf`
    }
  },
  components: {
    pdf
  },
  mounted() {
    this.src = pdf.createLoadingTask(this.publicPath);
    if (!this.src) {
      return;
    }
		this.src.promise.then(pdf => {
			this.numPages = pdf.numPages;
		});
	}
}
</script>

<style>
</style>